import { RouterIcon } from 'src/app/root/model/root.model';
import { CustomerRole } from '../constants';

export interface RouteMetadata {
    route: string;
    title: string;
    icon: string;
    api?: string;
    hasRole?: CustomerRole;
}

export const defaultRouteMetadata: RouteMetadata = {
    route: '',
    title: 'common.unknown',
    icon: RouterIcon.DEFAULT,
};

export const routesMetadata: RouteMetadata[] = [
    { route: '/about', title: 'about', icon: RouterIcon.ABOUT },
    { route: '/about/roadmap', title: 'roadmap', icon: RouterIcon.ROADMAP },
    {
        route: '/alerts',
        title: 'alert.plural',
        icon: RouterIcon.ALERTS,
        hasRole: CustomerRole.OEM,
    },
    {
        route: '/alerts/:id',
        title: 'alert',
        icon: RouterIcon.ALERTS,
        api: 'alerts',
        hasRole: CustomerRole.OEM,
    },
    {
        route: '/announcements',
        title: 'announcement.plural',
        icon: RouterIcon.ANNOUNCEMENTS,
    },
    {
        route: '/announcements/:id',
        title: 'announcement',
        icon: RouterIcon.ANNOUNCEMENTS,
    },
    {
        route: '/billing/:id',
        title: 'bill.billing',
        icon: RouterIcon.CASH,
        hasRole: CustomerRole.Super,
    },
    {
        route: '/dashboards',
        title: 'dashboard.plural',
        icon: RouterIcon.DASHBOARDS,
    },
    {
        route: '/dashboards/overview',
        title: 'utilities.dashboard_overview',
        icon: RouterIcon.DASHBOARD_OVERVIEW,
        hasRole: CustomerRole.Super,
    },
    {
        route: '/dashboards/:id',
        title: 'dashboard',
        icon: RouterIcon.DASHBOARDS,
        api: 'dashboards',
    },
    {
        route: '/data-files',
        title: 'data_file.plural',
        icon: RouterIcon.DATA_FILES,
    },
    {
        route: '/data-files/:id/charts',
        title: 'data_file',
        icon: RouterIcon.DATA_FILES,
        api: 'dataFiles',
    },
    {
        route: '/data-files/:id/map',
        title: 'data_file',
        icon: RouterIcon.DATA_FILES,
        api: 'dataFiles',
    },
    {
        route: '/data-files/:id',
        title: 'data_file.plural',
        icon: RouterIcon.DATA_FILES,
        api: 'dataFiles',
    },
    // TODO: remove this when /data-files is ready for production
    {
        route: '/data-files-alt',
        title: 'data_file.plural',
        icon: RouterIcon.DATA_FILES,
    },
    {
        route: '/devices/connections',
        title: 'device.connections',
        icon: RouterIcon.DEVICES_CONNECTION_LOG,
        hasRole: CustomerRole.Super,
    },
    {
        route: '/device-simulators',
        title: 'utilities.simulator',
        icon: RouterIcon.SIMULATOR,
        hasRole: CustomerRole.Super,
    },
    { route: '/devices', title: 'device.plural', icon: RouterIcon.DEVICES },
    {
        route: '/devices/:id/connection-log',
        title: 'device.connection_log',
        icon: RouterIcon.DEVICES_CONNECTION_LOG,
    },
    {
        route: '/devices/:id/recent-data',
        title: 'data.recent',
        icon: 'timetable',
    },
    {
        route: '/devices/:id',
        title: 'device',
        icon: RouterIcon.DEVICES,
        api: 'devices',
    },
    {
        route: '/geo-fences',
        title: 'geo_fence.plural',
        icon: RouterIcon.GEO_FENCING,
        hasRole: CustomerRole.Dealer,
    },
    {
        route: '/geo-fences/:id',
        title: 'geo_fence',
        icon: RouterIcon.GEO_FENCING,
        api: 'geoFences',
        hasRole: CustomerRole.Dealer,
    },
    { route: '/home', title: 'common.home', icon: RouterIcon.HOME },
    { route: '/models', title: 'model.plural', icon: RouterIcon.MODELS },
    {
        route: '/models/:modelId/software-versions',
        title: 'software_version.plural',
        icon: RouterIcon.SOFTWARE_VERSIONS,
        hasRole: CustomerRole.OEM,
    },
    {
        route: '/models/:modelId/can-databases/:id',
        title: 'can_database.edit',
        icon: RouterIcon.DEFAULT,
        hasRole: CustomerRole.OEM,
    },
    {
        route: '/models/:id',
        title: 'model',
        icon: RouterIcon.MODELS,
        api: 'models',
    },
    {
        route: '/models/:modelId/modules/:id',
        title: 'module.release.plural',
        icon: RouterIcon.RELEASES,
        api: 'modules',
    },
    {
        route: '/organizations',
        title: 'organization.plural',
        icon: RouterIcon.ORGANIZATIONS,
        hasRole: CustomerRole.Dealer,
    },
    {
        route: '/organizations/:id',
        title: 'organization',
        icon: RouterIcon.ORGANIZATIONS,
        api: 'organizations',
        hasRole: CustomerRole.Dealer,
    },
    {
        route: '/page-views',
        title: 'utilities.page_views',
        icon: RouterIcon.PAGE_VIEWS,
        hasRole: CustomerRole.Super,
    },
    { route: '/reports', title: 'report.plural', icon: RouterIcon.REPORTS },
    {
        route: '/reports/:id',
        title: 'report.plural',
        icon: RouterIcon.REPORTS,
        api: 'reports',
    },
    { route: '/settings', title: 'setting.plural', icon: RouterIcon.SETTINGS },
    { route: '/users', title: 'user.plural', icon: RouterIcon.USERS },
    {
        route: '/users/:id',
        title: 'user',
        icon: RouterIcon.USERS,
        api: 'users',
    },
    {
        route: '/widgets',
        title: 'custom_widget.plural',
        icon: RouterIcon.CUSTOM_WIDGETS,
        hasRole: CustomerRole.OEM,
    },
    {
        route: '/widgets/:id',
        title: 'custom_widget',
        icon: RouterIcon.CUSTOM_WIDGETS,
        api: 'widgets',
        hasRole: CustomerRole.OEM,
    },
];
